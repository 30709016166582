<template>
  <v-container class="py-6" >
    <ShopCommonTopBar />

    <v-row>
      <v-col cols="12" lg="6" md="8" sm="10" class="mx-auto" >
        <v-card elevation="0">
          <v-card-title style="padding: 16px 0;" class="d-flex justify-center">
            <span class="text-h5 fw-600 text-center">
              {{ $t('congratulations') }}
            </span>
          </v-card-title>
        </v-card>

        <div class="logo d-flex justify-center">
          <img :src="require('@/assets/img/shop-register-finish.png')" height="144" @error="imageFallback($event)" />
        </div>

        <v-card v-if="getSellerAppDownUrl" class="mb-6">
          <v-card-title class="justify-center">
            <h3 class="text-h5 fw-700 mb-md-0 text-center">{{ $t('download_the_professional_app_tips') }}</h3>
          </v-card-title> 

          <v-row justify="center">
            <v-col cols="9">
              <v-btn
                x-large
                class="px-12 mb-4 w-100"
                elevation="0"
                color="primary"
                @click.stop="downloadApp"
              >
                <img :src="require('@/assets/img/download-white.png')" style="width: 20px;height: 20px;margin-right: 7px;" />
                {{ $t('download_app_for_seller') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card>

        <v-card class="mb-6">
          <v-card-title class="justify-center">
            <h3 class="text-h5 fw-700 mb-md-0">{{ $t('continue_to_login') }}</h3>
          </v-card-title>

          <v-row justify="center">
            <v-col cols="9">
              <v-btn
                x-large
                class="px-12 mb-4 w-100"
                elevation="0"
                color="primary"
                @click="routeToLogin"
              >
                {{ $t('login') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card>

      </v-col>
    </v-row>

    <v-dialog
      v-model="dialogCard"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          {{ $t('loading please wait') }}...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
// component
import ShopCommonTopBar from '@/components/shop/ShopCommonTopBar'

// vuex
import { mapGetters } from 'vuex'

export default {
  name: 'ShopRegisterEnd',

  components: {
    ShopCommonTopBar
  },

  computed: {
    ...mapGetters('app', ['getSellerAppDownUrl', 'getSellerH5Url', 'h5Url'])
  },

  data() {
    return {
      dialogCard: false
    }
  },

  methods: {
    downloadApp() {
      window.location.href = this.getSellerAppDownUrl
    },

    routeToLogin() {
      this.dialogCard = true

      if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
        window.location.href = this.getSellerH5Url
      }else{
        window.location.href = this.h5Url
      }
    }
  }
}
</script>

<style>

</style>